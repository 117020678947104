import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
import { format } from 'date-fns';
import { mix } from '@theme-ui/color';
import Footer from '../components/footer';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const Card = makeShortcode("Card");
const Text = makeShortcode("Text");
const Heading = makeShortcode("Heading");
const Badge = makeShortcode("Badge");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Projects`}</h1>
    <SourceList filter='projects' mdxType="SourceList">
  {projects => <Flex sx={{
        flexWrap: 'wrap',
        ml: theme => `-${theme.space[2]}px`,
        mr: theme => `-${theme.space[2]}px`
      }} mdxType="Flex">
      {projects.map((edge, index) => {
          const {
            frontmatter: {
              title,
              date,
              tags
            },
            fields: {
              slug
            }
          } = edge.node;
          return <Box key={index} sx={{
            mb: 3,
            maxWidth: ['100%', '50%', '33.333%'],
            width: ['100%', '50%', '33.333%']
          }} mdxType="Box">
            <Link href={slug} sx={{
              textDecoration: 'none'
            }} mdxType="Link">
              <Card sx={{
                ml: 2,
                mr: 2,
                p: 3
              }} mdxType="Card"> 
                <Text sx={{
                  fontSize: 0,
                  color: 'muted'
                }} mdxType="Text">{format(new Date(date), 'd-MMM-u')}</Text>
                <Heading variant='styles.h4' sx={{
                  color: 'text'
                }} mdxType="Heading">{title}</Heading>
                {tags.map((tag, index) => {
                  const {
                    name,
                    count,
                    percent
                  } = tag;
                  return <Badge key={index} variant='primary' sx={{
                    mr: 2,
                    mb: 2,
                    color: mix('success', 'secondary', `${index / tags.length}`),
                    borderColor: mix('success', 'secondary', `${index / tags.length}`)
                  }} mdxType="Badge">
                      {tag}
                    </Badge>;
                })}
                <Text sx={{
                  textDecoration: 'underline'
                }} mdxType="Text">View project</Text>
              </Card>
            </Link>
          </Box>;
        })}
    </Flex>}
    </SourceList>
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      